// Supplier Info Routes...
const farmMachinery = '/farmMachinery/config/supplier-info/'
export const farmMachineryStore = farmMachinery + 'store'
export const farmMachineryList = farmMachinery + 'list'
export const farmMachineryToggleStatus = farmMachinery + 'toggle-status'
export const farmMachineryUpdate = farmMachinery + 'update'

// Machine Type Routes...
const machineType = '/farmMachinery/config/machine-type/'
export const machineTypeStore = machineType + 'store'
export const machineTypeList = machineType + 'list'
export const machineTypeToggleStatus = machineType + 'toggle-status'
export const machineTypeUpdate = machineType + 'update'

// Circular Info Api Routes...
export const circularInfoList = '/dae/config/circular/list'
export const circularInfoStore = '/dae/config/circular/store'
export const circularInfoUpdate = '/dae/config/circular/update'
export const circularInfoStatus = '/dae/config/circular/toggle-status'
export const circularInfoDestroy = '/dae/config/circular/destroy'

// Brand Api Routes...
export const brandList = '/farmMachinery/config/brand/list'
export const brandStore = '/farmMachinery/config/brand/store'
export const brandUpdate = '/farmMachinery/config/brand/update'
export const brandStatus = '/farmMachinery/config/brand/toggle-status'

// Farmer Info Routes...
const machineryFarmer = '/farmMachinery/config/farmer-info/'
export const machineryFarmerStore = machineryFarmer + 'store'
export const machineryFarmerList = machineryFarmer + 'list'
export const machineryFarmerToggleStatus = machineryFarmer + 'toggle-status'
export const machineryFarmerUpdate = machineryFarmer + 'update'

export const importer = machineryFarmer + 'importer'

// Farm Machinery Assign Routes...
const supplierAssign = '/farmMachinery/config/supplier-wise-machine-assign/'
export const SupplierMachineStore = supplierAssign + 'store'
export const SupplierMachineList = supplierAssign + 'list'
export const SupplierMachineToggleStatus = supplierAssign + 'toggle-status'
export const SupplierMachineUpdate = supplierAssign + 'update'

// Attachment Routes...
const attachment = '/farmMachinery/config/attachment/'
export const attachmentStore = attachment + 'store'
export const attachmentList = attachment + 'list'
export const attachmentUpdate = attachment + 'update'

<template>
    <b-container fluid>
        <div class="profile-card">
            <img :src="incentiveGrantServiceBaseUrl + 'storage/' + detailsData.photo" class="profile-picture">
            <h2> {{ ($i18n.locale=='bn') ? detailsData.name_bn : detailsData.name }} </h2>
            <p style="line-height: 1;">{{ ($i18n.locale == 'bn' ? '০': '0') + $n(detailsData.mobile_no, { useGrouping: false }) }}</p>
            <p style="line-height: 1;">{{ detailsData.email }}</p>
            <!-- <p style="line-height: 1;">{{ $n(detailsData.user_id, { useGrouping: false }) }}</p> -->
        </div>
        <template>
            <div style="font-size:18px; background-color: var(--brand-color);">
            <h5 class="text-white text-center">
                {{ $t('globalTrans.company_name') }}
            </h5>
            </div>
        </template>

        <b-table-simple bordered>
            <b-tr>
                <b-th class="text-center"> {{ $t('globalTrans.company_name') }} </b-th>
                <b-th class="text-center"> {{ $t('globalTrans.company_mobile') }} </b-th>
                <b-th class="text-center"> {{ $t('dae_config.tin') }} </b-th>
                <b-th class="text-center"> {{ $t('dae_config.vat_regis') }} </b-th>
                <b-th class="text-center"> {{ $t('dae_config.trade_regis') }} </b-th>
            </b-tr>
            <b-tr>
                <b-td class="text-center"> {{ ($i18n.locale=='bn') ? detailsData.company_name_bn : detailsData.company_name }} </b-td>
                <b-td class="text-center"> {{ ($i18n.locale == 'bn' ? '০': '0') + $n(detailsData.company_mobile, { useGrouping: false }) }} </b-td>
                <b-td class="text-center"> {{ $n(detailsData.company_tin, { useGrouping: false }) }} </b-td>
                <b-td class="text-center"> {{ $n(detailsData.vat_registration_no, { useGrouping: false }) }} </b-td>
                <b-td class="text-center"> {{ $n(detailsData.trade_registration_no, { useGrouping: false }) }} </b-td>
            </b-tr>
          </b-table-simple>

        <template>
            <div style="font-size:18px; background-color: var(--brand-color);">
            <h5 class="text-white text-center">
                {{ $t('admission_form.add_info') }}
            </h5>
            </div>
        </template>
        <b-table-simple bordered>
            <b-tr>
                <b-th class="text-center" v-if="detailsData.area_type_id"> {{ $t('org_pro.area_type') }} </b-th>
                <b-th class="text-center" v-if="detailsData.division_id"> {{ $t('org_pro_division.division') }} </b-th>
                <b-th class="text-center" v-if="detailsData.district_id"> {{ $t('org_pro_district.district') }} </b-th>
                <b-th class="text-center" v-if="detailsData.upazila_id"> {{ $t('org_pro_upazilla.upazilla') }} </b-th>
                <b-th class="text-center" v-if="detailsData.union_id"> {{ $t('org_pro_union.union') }} </b-th>
                <b-th class="text-center" v-if="detailsData.city_corporation_id"> {{ $t('org_pro.city_corporation') }} </b-th>
                <b-th class="text-center" v-if="detailsData.address_bn && detailsData.address_en"> {{ $t('globalTrans.address') }} </b-th>
            </b-tr>
            <b-tr>
                <b-td class="text-center" v-if="detailsData.area_type_id"> {{ getAreaType(detailsData.area_type_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.division_id"> {{ getDivisionName(detailsData.division_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.district_id"> {{ getDistrictName(detailsData.district_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.upazila_id"> {{ getUpazilaName(detailsData.upazila_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.union_id"> {{ getUnionName(detailsData.union_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.city_corporation_id"> {{ getCityCorporationName(detailsData.city_corporation_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.address_bn && detailsData.address_en"> {{ ($i18n.locale=='bn') ? detailsData.address_bn : detailsData.address_en }} </b-td>
            </b-tr>
          </b-table-simple>
          <template>
            <div style="font-size:18px; background-color: var(--brand-color);">
            <h5 class="text-white text-center">
                {{ $t('educational_management.permanent_address') }}
            </h5>
            </div>
        </template>
          <b-table-simple bordered>
            <b-tr>
                <b-th class="text-center" v-if="detailsData.per_area_type_id"> {{ $t('org_pro.area_type') }} </b-th>
                <b-th class="text-center" v-if="detailsData.per_division_id"> {{ $t('org_pro_division.division') }} </b-th>
                <b-th class="text-center" v-if="detailsData.per_district_id"> {{ $t('org_pro_district.district') }} </b-th>
                <b-th class="text-center" v-if="detailsData.per_upazila_id"> {{ $t('org_pro_upazilla.upazilla') }} </b-th>
                <b-th class="text-center" v-if="detailsData.per_union_id"> {{ $t('org_pro_union.union') }} </b-th>
                <b-th class="text-center" v-if="detailsData.per_city_corporation_id"> {{ $t('org_pro.city_corporation') }} </b-th>
                <b-th class="text-center" v-if="detailsData.per_address_en && detailsData.per_address_bn"> {{ $t('globalTrans.address') }} </b-th>
            </b-tr>
            <b-tr>
                <b-td class="text-center" v-if="detailsData.per_area_type_id"> {{ getAreaType(detailsData.per_area_type_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.per_division_id"> {{ getDivisionName(detailsData.per_division_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.per_district_id"> {{ getDistrictName(detailsData.per_district_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.per_upazila_id"> {{ getUpazilaName(detailsData.per_upazila_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.per_union_id"> {{ getUnionName(detailsData.per_union_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.per_city_corporation_id"> {{ getCityCorporationName(detailsData.per_city_corporation_id) }} </b-td>
                <b-td class="text-center" v-if="detailsData.per_address_en && detailsData.per_address_bn"> {{ ($i18n.locale=='bn') ? detailsData.per_address_bn : detailsData.per_address_en }} </b-td>
            </b-tr>
          </b-table-simple>
    </b-container>
  </template>

<script>
import { incentiveGrantServiceBaseUrl } from '@/config/api_config'
    export default {
    name: 'FormLayout',
    props: ['item'],
    created () {
        this.detailsData = this.item
    },
    data () {
        return {
            incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
            detailsData: {},
            slOffset: 1
        }
    },
    computed: {
        localeLang () {
        return this.$i18n.locale
        },
        listData () {
        const objectData = this.item
        return objectData.filter(data => data.id === this.item.id)
        }
    },
    methods: {
        getAreaType (status) {
        if (status === 1) {
            return this.$i18n.locale === 'en' ? 'City Corpoation' : 'সিটি কর্পোরেশন'
        } else if (status === 2) {
            return this.$i18n.locale === 'en' ? 'Pauroshoba' : 'পৌরসভা'
        } else if (status === 3) {
            return this.$i18n.locale === 'en' ? 'Union' : 'ইউনিয়ন'
        }
        },
        getDivisionName (divisionId) {
        const obj = this.$store.state.commonObj.divisionList.find(el => el.value === parseInt(divisionId))
        if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
        }
        },
        getDistrictName (districtId) {
        const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(districtId))
        if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
        }
        },
        getUpazilaName (upazilaId) {
        const obj = this.$store.state.commonObj.upazilaList.find(el => el.value === parseInt(upazilaId))
        if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
        }
        },
        getUnionName (unionID) {
            const obj = this.$store.state.commonObj.unionList.find(el => el.value === parseInt(unionID))
            if (obj !== undefined) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            }
        },
        getCityCorporationName (upazilaId) {
            const obj = this.$store.state.commonObj.cityCorporationList.find(el => el.value === parseInt(upazilaId))
            if (obj !== undefined) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            }
        }
    }
    }
</script>

  <style>
  .profile-card {
    margin: 10px;
    padding: 10px;
    text-align: center;
    margin: 0 auto;
  }

  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 10px;
  }
</style>
